.page-table-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .form-container {
    padding: 20px 10px;
    background-color: #fafafa;
    margin-bottom: 20px;
    border-radius: 0.5rem;
    box-sizing: border-box;
    box-shadow: 0px 1px 1px #eee inset,
      0px -1px 1px #eee inset,
      1px 0px 1px #eee inset,
      1px 0px 1px #eee;

    .container-fluid {
      padding: 0 0 0 35px;

      .button-layout {
        margin-right: 35px;
      }
    }
  }
}