.HOV-download-btn.MuiButton-contained {
  width: 10rem;
  height: 40px;
  border-radius: 1rem;
  font: normal normal 200 0.85rem/24px "Roboto";
  letter-spacing: 0px;
  background: #00358E;
  color: #ffffff;
  text-transform: capitalize;

  &:hover {
    background: #00358E;
    color: #ffffff;
  }
}