.card-container {
  margin: 1.2vw 1.65vw;

  .hov-card {
    flex-direction: column;
    background-color: #2A7DE1;
    height: 14vw;
    width: 14vw;
    border-radius: 0.5rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    &:hover {
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
        0px 1px 5px 0px rgb(0 0 0 / 12%);
    }

    .users-image {
      width: 3.5vw;
    }

    .title {
      text-align: center;
      font: normal normal 500 1.2vw/1.87vw "Roboto";
      letter-spacing: 1px;
      color: #ffffff;
      text-transform: uppercase;
      text-decoration: none;
    }
  }

  .MuiBadge-badge.MuiBadge-anchorOriginTopRightRectangle {
    background-color: #d26400;
    color: #fff;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    top: 6px;
    right: 6px;
  }
}