.attribute-select {
    // background-color: yellow;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    .configText {
        font: normal normal 200 0.9rem/27px "Roboto";
        margin-top: 1px;
    }
}