.description {
  // background: yellow;
  display: block;
  font: normal italic 100 0.7rem/24px "Roboto";
  height: fit-content;
  padding: 0;
}
.input-primary {
  border-radius: 0.3rem;
  font: normal normal 100 0.9rem/24px "Roboto";
  height: 42px;
  outline: none;
  width: 90%;
  box-shadow: 0px 1px 1px #eee inset,
      0px -1px 1px #eee inset,
      1px 0px 1px #eee inset,
      1px 0px 1px #eee;
  border: none;

  &:focus {
    box-shadow: 0px 1px 1px #eee inset,
      0px -1px 1px #eee inset,
      1px 0px 1px #eee inset,
      1px 0px 1px #eee;
    border-radius: 0.3rem;
    outline: none;
    border: none;
  }
}