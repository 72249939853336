@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@600;700&display=swap');

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(./assets/fonts/Roboto-Medium.ttf) format("truetype");
}

:root {
  background: #ffffff;
}

.downloadColumn {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 35px;

}

.downloadRow {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  padding: 0;
  margin: 0;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained.downloadPDFBtn {
  width: 192px;
  height: 40px;
  background: #006aa6;
  border-radius: 27px;
  font: normal normal 600 17px/23px 'Roboto';
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  margin-left: 11px;
}

::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 10px;
}