.logs-container {
  display: flex;
  flex-direction: column;
  padding: 10px;

  .overall-status {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .headers {
      font: normal normal 600 17px/34px 'Roboto';
    }

    .status {
      color: #fff;
      margin: auto 10px;
      border-radius: 1rem;
      padding: 2px 10px;
      text-transform: lowercase;
    }
  }

  .ami {
    display: flex;
    align-items: center;
    margin: 10px 0;

    .headers {
      font: normal normal 600 17px/34px 'Roboto';
    }

    .base-ami {
      margin: auto 10px;
    }
  }

  .step-status {
    margin: 10px 0;

    .headers {
      font: normal normal 600 17px/34px 'Roboto';
    }
  }

  .rows-view {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    .row-headers {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 15px 5px;
      background-color: #2A7DE1;
      border-radius: 5px 5px 0 0;

      .table-header {
        flex: 1;
        color: #000;
        color: #fff;
      }
    }

    .row-val {
      height: 400px;
      overflow-y: scroll;

      .steps {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 5px;

        &:nth-child(odd) {
          background: #fafafa;
        }

        .header {
          flex: 1;
          color: #616161;

          .value {
            width: fit-content;
            border-radius: 1rem;
            padding: 5px 10px;
            color: #616161;
          }
        }
      }
    }
  }

  .s3-scripts-logs {
    margin: 10px 0;

    .headers {
      font: normal normal 600 17px/34px 'Roboto';
    }
  }

  .s3-logs {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    height: 400px;
    overflow-y: scroll;

    .s3 {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &:nth-child(odd) {
        background: #fafafa;
      }

      .log-time {
        flex: 1;
        color: #616161;
      }

      .log-value {
        flex: 4;
        color: #616161;
      }
    }
  }
}

.script-header {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .header-item {
    display: flex;
    width: fit-content;
    margin: 10px 0;
    justify-content: space-evenly;
    padding: 5px;
  }
}

.script {
  line-height: 2rem;
  padding: 5px;
  background: #fafafa;
}