.dashboard-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 4.5vw;
  margin-right: 4.5vw;
  margin-top: 9.12vw;
  min-width: 52vw;

  a {
    text-decoration: none;
  }
}

@media screen and (max-width: 1170px) {
  .dashboard-cards-container {
    margin-left: 0;
    margin-right: 0;

    a:hover {
      text-decoration: none;
    }
  }
}