.accounts-table {
  padding: auto 1vw;

  .ag-root-wrapper.ag-layout-normal.ag-ltr {
    border-radius: 0.5rem;
    border: 1px solid #eeeeee;
  }

  .ag-header-viewport {
    background-color: #2A7DE1;
    color: white;
    text-transform: uppercase;
  }

  .ag-header-cell {
    border-right: none;

    // border-right: 2px solid #eeeeee;
    &:last-child {
      border-right: none;
    }
  }

  .ag-header-cell-text {
    font: normal normal 900 1rem/30px "Roboto";
    letter-spacing: 0px;
    color: #fff;
  }

  .ag-row.ag-row-no-focus.ag-row-even.ag-row-level-0.ag-row-position-absolute {
    border: none;

    &:nth-child(odd) {
      background-color: #fff;
    }
  }

  .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value {
    font: normal normal 600 1.25/27px "Roboto";
    letter-spacing: 0;
    color: #000000;
    border-right: none;

    &:last-child {
      border-right: none;
    }
  }

  .ag-header-cell-label {
    text-align: center;
  }

  .ag-center-cols-container {
    width: 100%;
  }

  .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value.ag-cell-focus,
  .ag-cell {
    border: none;
  }

  .ag-center-cols-clipper {
    .ag-center-cols-viewport {
      .ag-center-cols-container {
        width: 100vw;
      }
    }
  }

  .altBg {
    background-color: #fff;
    border-bottom: 1px solid #eeeeee;
  }

  .ag-paging-panel {
    border: none;
    border-radius: 0 0 0.5rem 0.5rem;
    border-top: 1px solid #e0e0e0;
    height: 50px;
  }
}

.ag-theme-alpine {
  font-family: "Roboto";
}

.ag-theme-alpine .ag-row {
  border-bottom: 1px solid #eeeeee;
  background: white;
}