.Error {
  text-align: center;
  background: transparent;
}

.Err-header {
  display: flex;
  padding: 20px;
  margin: 10px auto;
  align-items: center;
  justify-content: flex-start;
}

.Err-message-block {
  padding: 20px;
  width: 50%;
  margin: 10px auto;
  text-align: left;
}

.message {
  color: rgb(51, 51, 51);
  font-weight: 300;
  text-decoration: solid;
}

.Err-code {
  font-size: 100px;
  font-weight: 600;
  padding: auto;
  margin: 0;
}