.hov-checkbox {
  .hov-form-group {
    display: block;
    margin-bottom: 0.6rem;
  }

  .hov-form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  .hov-form-group label {
    position: relative;
    cursor: pointer;
  }

  .hov-form-group label:before {
    content: "";
    appearance: none;
    background-color: transparent;
    border: 1px solid #54585a;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 0.5rem;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 4px;
  }

  .hov-form-group input:checked+label:after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 7.5px;
    width: 5px;
    height: 12px;
    border: solid #54585a;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}