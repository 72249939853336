.home {
  background-color: #ffffff;

  a {
    text-decoration: none;
  }

  .comp-fff {
    background-color: #fff;
  }

  .top {
    -webkit-border-bottom-right-radius: 8px;
    -khtml-border-bottom-right-radius: 8px;
    -moz-border-radius-bottomright: 8px;
    border-bottom-right-radius: 8px;
    padding: 0.25em;
    background-color: #00358E;
  }

  .bot {
    -webkit-border-top-right-radius: 8px;
    -khtml-border-top-right-radius: 8px;
    -moz-border-radius-topright: 8px;
    border-top-right-radius: 8px;
    padding: 0.25em;
    background-color: #00358E;
  }

  .MuiDrawer-paperAnchorDockedLeft {
    border-right: none;
  }

  .currentTabCls {
    font: normal normal bold 25px/34px 'Roboto';
    letter-spacing: 0;
    color: #ffffff;
    text-transform: uppercase;
    overflow: initial;
  }

  .logo-container {
    margin-top: 0.75rem;
    margin-right: 0.75rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 270px;

    .logo-text {
      margin-left: 0.5rem;
    }
  }

  .logo-name {
    text-align: left;
    font: normal normal 500 1rem/26px 'Roboto';
  }

  .veriskLogo {
    width: 110px;
    height: 35px;
  }

  .drawer-tab {
    padding: 1rem;
    font: normal normal bold 19px/26px 'Roboto';
    letter-spacing: 0;
    color: #ffffff;
    text-transform: uppercase;

    &:nth-child(1) {
      margin-top: 5px;
    }
  }

  .drawer-tab.active-tab {
    background: white;
    color: #2A7DE1;
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
  }

  .MuiListItemIcon-root {
    min-width: 35px;
  }

  .MuiAppBar-colorPrimary {
    background-color: #2A7DE1;
  }

  .drawer-icon {
    height: 26px;
    color: #fff;
    margin-right: 20px;
  }

  .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
    background-color: #00358E;
    color: #fff;

    .drawer-icon-text {
      text-transform: uppercase;

      span {
        font: bold 14px 'Roboto';
      }
    }

    .MuiIconButton-root {
      color: #fff;
    }
  }

  .MuiPaper-root.MuiDrawer-paper.makeStyles-drawerClose-8.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.MuiPaper-elevation0 {
    width: 50px;
  }

  .activeTab {
    background: white;
    color: #2A7DE1;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
  }

  .MuiButtonBase-root.MuiButton-root.MuiButton-contained.hov-filter {
    box-shadow: none;
    border-radius: 27px;
    padding: 15px;
    margin-left: 19px;
    background-color: #00358E;
    color: #fff;
    text-transform: capitalize;
    min-width: 186px;
    font: normal normal 600 22px/30px 'Roboto';
    letter-spacing: 0;
    color: #ffffff;
  }

  .MuiButtonBase-root.MuiButton-root.MuiButton-text.logout.MuiButton-textPrimary {
    text-transform: capitalize;
    font: normal normal 200 0.9rem/24px 'Roboto';
    letter-spacing: 0;
    color: #ffffff;
    margin: auto 13px;
  }

  .MuiBadge-badge.MuiBadge-anchorOriginTopRightRectangle {
    background-color: #d26400;
    color: #fff;
  }

  .MuiIconButton-root {
    padding: 0;
  }

  .MuiButtonBase-root.MuiIconButton-root {
    color: white;
    padding: 0;
  }

  .MuiFormControl-root {
    bottom: 6px;
  }

  .notification-container {
    display: flex;
    align-items: center;

    .MuiSvgIcon-root.MuiSelect-icon {
      fill: white;
    }

    .MuiSelect-select {
      min-width: 0;
      padding-bottom: 20px;
    }
  }

  .search__input {
    width: 100%;
    padding: 16px 24px;
    transition: transform 250ms ease-in-out;
    font-size: 14px;
    line-height: 18px;
    background-color: #fff;
    border-radius: 50px;
    border: 1px solid #fff;
    margin-left: 43px;
    padding-left: 60px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #e9ecef;
    border-radius: 27px;

    &:focus-visible {
      outline: none;
    }
  }

  .search-icon {
    position: absolute;
    top: 15px;
    left: 59px;
  }

  .search-container {
    width: 100%;
    margin-right: 45px;
  }

  .user-info-container {
    margin-left: 30px;

    .user-name {
      font: normal normal 500 1.3rem/30px 'Roboto';
      letter-spacing: 0;
      color: #ffffff;
    }

    .user-role {
      font: normal normal bold 20px/27px 'Roboto';
      letter-spacing: 0;
      color: #a2dff6;
    }
  }

  .MuiButtonBase-root.MuiListItem-root.drawer-tab.MuiListItem-gutters.MuiListItem-button {
    margin-top: 0;
  }

  .MuiPaper-root.MuiDrawer-paper.makeStyles-drawerOpen-7.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.MuiPaper-elevation0 {
    overflow-x: hidden;
  }
}