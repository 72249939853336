.topbar-main-container {
  width: 99%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.2vw;
  padding-right: 0.2vw;

  .heading {
    font: normal normal 500 22px/30px 'Roboto';
    letter-spacing: 0px;
    color: #212121;
    margin: 20px auto;
    flex: 1;
  }

  .refresh-icon {
    font: normal normal 500 22px/30px 'Roboto';
    letter-spacing: 0px;
    color: #000000;
    margin: 20px auto;
    flex: 1;
  }

  .action-layout {
    flex: 4;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .search-bar {
      flex: 2;
      margin: 20px auto;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      height: 38px;

      #filter-text-box {
        width: 90%;
        border: 1px solid #9e9e9e;
        border-radius: 1rem;
        height: 40px;
        outline: none;
        text-indent: 15px;
        padding: 0;
        font: normal normal 200 1rem/27px "Roboto";
        box-shadow: none;

        &::-webkit-search-cancel-button {
          margin-right: 10px;
          margin-left: 5px;
          cursor: pointer;
        }
      }
    }

    .range-select {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1;
      font: normal normal 200 0.9rem/27px "Roboto";

      .range-and-total-pages {
        margin-top: 2px;
      }

      .row-select {
        width: 60px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        font: normal normal 200 0.9rem/27px "Roboto";
      }
    }

    .pagination-pdf-btn-container {
      margin: 20px 0;
      flex: 1;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      .pagination-dropdown {
        flex: 2;
        display: flex;
        justify-content: flex-end;
      }

      .button-container {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: '1px solid red';
    }

    .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
      &:focus {
        background-color: transparent;
      }
    }

    .MuiPagination-root {
      padding-top: 4px;
    }
  }

  .action-layout-hidden {
    display: none;
  }
}

.shrinked {
  width: 91%;
}