.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.position-relative {
  position: relative;
}

.d-flex {
  display: flex;
}

.cursor-pointer {
  cursor: pointer;
}

.w-0 {
  width: 0;
}

// hides horizontal overflow in LHS drawer
.MuiPaper-root.MuiDrawer-paper {
  overflow-x: hidden;
}

.flex-end {
  justify-content: flex-end;
}

.page-headings {
  font: normal normal 800 25px/34px 'Roboto';
  letter-spacing: 0px;
  color: #000000;
  margin: 31px 0;
}

.page-table-container {
  padding-left: 2vw;
  padding-right: 2vw;
}

// setting bootstrap modal z-index to 1200 to actually keep it above material drawer
body {
  .fade.modal.show {
    z-index: 1200;
  }
}

// .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.altBg.ag-cell-value.ag-cell-focus {
//   border-right: 3px solid white;
// }

// .accounts-table
//   .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value.ag-cell-focus,
// .accounts-table .ag-cell {
//   border-right: 3px solid white;
// }

// .altBg {
//   // background-color: #ececec;
// }

.MuiList-root.MuiList-padding {
  a:hover {
    text-decoration: none !important;
  }
}

// xtral-large-modal-size
.hov-modals {
  .modal-dialog {
    width: 80vw !important;
    margin: 0 auto;
    max-width: 80vw !important;

    .modal-content {
      height: 80vh;
    }
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    width: 85vw !important;
    // background-color: yellow;
    margin: 20px auto;
    // margin-top: 30px;
    max-width: 85vw !important;
  }

  .modal-content {
    max-height: fit-content;
    // max-height: 90vh;
    margin: auto 0;
    // overflow-y: scroll;
    border-radius: 0.3rem !important;
  }
}

// removes blue glow on button clicked
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.loader {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}