.autoCompleteWithChips {
  font-family: "Roboto";
  letter-spacing: 0px;
  max-width: 100%;
  width: 90%;
  margin: auto 2rem 1rem 0;

  &:hover {
    outline: none;
    border: none;
  }
}