.action-btn {
  // background: yellow;
  height: 55px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: space-between;

  .button {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .btn {
      background: transparent;
    }
  }
}